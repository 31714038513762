import { ListenerMode } from '@fiverr-private/obs';
import { getMetaTagValue } from '@fiverr-private/layout-lib/getMetaTagValue';
import { listener as monicoreListener } from '@fiverr-private/monicore';

/**
 * Initialize Monicore listener using existing DOM metatags.
 */

const mode = getMetaTagValue('app_environment') as ListenerMode;
const facility = getMetaTagValue('fiverrmeta:facility');
const relay = getMetaTagValue('browser_stats_api_v1');

monicoreListener({
    host: relay,
    facility,
    mode,
});
