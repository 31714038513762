import {
    bigQueryListener,
    mixpanelListener,
    loggerListener,
    statsListener,
    biankaListener,
    LogLevel,
    ListenerMode,
} from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';
import { getMetaTagValue } from '@fiverr-private/layout-lib/getMetaTagValue';
import { mandatoryScriptLoggerInterceptor } from './mandatoryScriptLoggerInterceptor';

/**
 * Initialize OBS listeners using existing DOM metatags.
 */

const mode = getMetaTagValue('app_environment') as ListenerMode;
const logLevel = getMetaTagValue('fiverrmeta:log_level') as LogLevel;
const facility = getMetaTagValue('fiverrmeta:facility') as string;
const pageName = getMetaTagValue('fiverrmeta:pagename') as string;
const biankaUrl = getMetaTagValue('browser_bianka_api') as string;
const relay = getMetaTagValue('browser_stats_api_v1');
const mixpanelToken = getMetaTagValue('gtm:mixpanel_token');

loggerListener({
    host: relay,
    facility,
    mode,
    logLevel,
}).intercept(mandatoryScriptLoggerInterceptor);

bigQueryListener({
    host: '/api/v1/activities',
    facility,
    mode,
});

mixpanelListener({
    token: mixpanelToken,
    mode,
    userId: getContext().userId,
    userGuid: getContext().userGuid,
});

statsListener({
    host: relay,
    mode,
});

biankaListener({
    pageName,
    mode,
    facility,
    baseUrl: biankaUrl,
}).setEnricher(() => {
    const { userId, locale, userGuid, pageCtxId } = getContext();
    return {
        userId,
        locale,
        uid: userGuid,
        hostname: window.location.hostname,
        page: {
            ctxId: pageCtxId,
        },
    };
});
